.good {
  background-color: crimson;
}

.great {
  background-color: blue;
}

.miss {
  background-color: gray;
}

/* for instruction icon animation */

/* @keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
} */

@keyframes FazeIn {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

.instruction-icon {
  animation: 1s ease-out 0s 1 FazeIn;
}
