.react-calendar-heatmap .color-1{
  fill: rgb(236, 253, 245);
}
.react-calendar-heatmap .color-2{
  fill: rgb(209, 250, 229);
}
.react-calendar-heatmap .color-3{
  fill: rgb(167, 243, 208);
}
.react-calendar-heatmap .color-4{
  fill: rgb(110, 231, 183);
}
.react-calendar-heatmap .color-5{
  fill: rgb(52, 211, 153);
}
.react-calendar-heatmap .color-6{
  fill: rgb(16, 185, 129);
}
.react-calendar-heatmap .color-7{
  fill: rgb(5, 150, 105);
}
.react-calendar-heatmap .color-8{
  fill: rgb(4, 120, 87);
}
.react-calendar-heatmap .color-9{
  fill: rgb(6, 95, 70);
}
.react-calendar-heatmap .color-10{
  fill: rgb(6, 78, 59);
}
