.bg {
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(-50deg, #f59e0b 50%, #db2777 50%);
  bottom: 0;
  left: -50%;
  opacity: 0.5;
  position: fixed;
  right: -50%;
  top: 0;
  z-index: -1;
}

.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 20s;
}

.bg3 {
  animation-duration: 14s;
}

/* @keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(15%);
  }
} */
